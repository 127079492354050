import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Button, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkeGuidesOLIntro: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Overload Gear - Intro</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_ol.webp"
            alt="Liberation"
          />
        </div>
        <div className="page-details">
          <h1>NIKKE Overload Gear (OL) - Intro</h1>
          <h2>
            Guide for the Overload Gear (OL) in NIKKE - the introduction part.
          </h2>
          <p>
            Last updated: <strong>17/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                Gear Overload is a feature in Nikke that allows you to take your
                Tier 9 gear further to the next level through Custom Modules.{' '}
                <strong>
                  An overloaded Tier 9 Manufacturer will convert into this Tier
                  10 gear, which provides higher stats, unlocks higher upgrade
                  levels, and offers up to three lines of additional built-in
                  buffs
                </strong>
                . This is called Overloaded Gear (OL), and is the endgame
                equipment that will finally allow your Nikkes to reach their
                maximum potential by fixing their shortcomings.
              </p>
              <h5>Reason for Overloading Gear</h5>
              <p>
                Not all Nikke(s) require OL. In fact, you probably shouldn't OL
                everyone because it can get expensive. There are four main
                reasons why we overload our gear:
              </p>
              <ul>
                <li>
                  OL increases the maximum enhancement level (and therefore
                  stats) and unlocks additional built-in buffs, which both
                  contribute to <strong>increased CP</strong>. This helps you
                  gain an advantage in Campaign & Arena, where there might be CP
                  discrepancy and stat penalty.
                </li>
                <li>
                  The Nikke benefits a lot from the{' '}
                  <strong>extra built-in buffs</strong> from OL. For instance,
                  Modernia's DPS will skyrocket if given Max Ammo.
                </li>
                <li>
                  The Nikke benefits from the <strong>increased stats</strong>{' '}
                  from OL, such as those who provide caster's ATK buffs.
                </li>
                <li>
                  The Nikke is your waifu, and you want her to perform better.
                </li>
              </ul>
              <p>
                Unless one of the four reasons above is true, we suggest not
                OL-ing to save materials. They can get scarce very quickly. In
                fact, even for veterans, we often run out of them and have to
                make sacrifices.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Unlocking Overload" />
              <StaticImage
                src="../../../images/nikke/generic/ol_1.webp"
                alt="Guide"
              />
              <p>
                As a prerequisite, you need to first{' '}
                <strong>clear Chapter 16-28 in Normal Campaign</strong>, which
                will unlock the current hardest difficulty Interception mode,
                Interception EX (also known as Special Interception, or SI,
                shown in the image above). This will drop T9 Manufacturer
                Equipment (T9M) and Custom Modules, two materials you will need
                to start your OL journey. In general, you should endeavor to
                deal as much damage as possible to the boss to obtain the
                highest possible reward. However, to actually procure the
                relevant materials, you will have to slay the boss (reach Reward
                Stage 9). You can ask for tips & tricks in our{' '}
                <a
                  href="https://discord.gg/prydwen"
                  target="_blank"
                  rel="noreferrer"
                >
                  Discord server
                </a>
                , and we'll be delighted to assist you.
              </p>
              <StaticImage
                src="../../../images/nikke/generic/ol_2.webp"
                alt="Guide"
              />
              <ol>
                <li>
                  For each successful kill, you have a 85% chance of obtaining a
                  Bonus Reward, which can be a Custom Module, ordinary T9
                  equipment, or a T9M.
                </li>
                <li>
                  Already taking the 85% into calculation, the drop rate of a
                  Custom Module is 26.67%, while the drop rate of T9M as a whole
                  is 26.75%.
                </li>
                <li>
                  Additionally, with any runs, you also get Manufacturer Arms
                  parts that can be opened once 200 to obtain a random T9M.
                </li>
                <li>
                  Since the T9M is random, you need to be lucky to get one
                  suitable for your Nikke, so yeah, it is a gacha.
                </li>
              </ol>
              <StaticImage
                src="../../../images/nikke/generic/ol_3.webp"
                alt="Guide"
              />
              <p>
                <i>
                  Manufacturer Arms (Yellow Circle), Custom Module (Green
                  Circle), T9M (Blue Circle)
                </i>
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Overloading Your Gear" />
              <p>
                To overload your gear, you need two ingredients: a Custom Module
                & a compatible T9M equipment (matching company). Level this
                equipment to level 5, and then the option to overload will
                finally appear. This will convert it into a T10 gear, which you
                can then level up again to +5.{' '}
              </p>
              <StaticImage
                src="../../../images/nikke/generic/ol_4.webp"
                alt="Guide"
              />
              <p>Here is a short demonstration.</p>
              <ul>
                <li>
                  <strong>Green Underline</strong>: The equipment should be T9.
                </li>
                <li>
                  <strong>Red Circle</strong>: The company logo should match the
                  company of the Nikke being overloaded. In this case, it is
                  Missilis.
                </li>
                <li>
                  <strong>Blue Circle</strong>: The class signifies that only
                  Attacker Units can equip this gear.
                </li>
                <li>
                  <strong>Yellow Circle</strong>: The gear needs to be Level 5
                  before you can overload it using a Custom Module.
                </li>
              </ul>
              <p>
                Once all the requirements are met, you can now continue with the
                procedure of overloading your gear.
              </p>
              <ol>
                <li>Open the gear you want to overload.</li>
                <li>
                  Select <strong>Equipment Modification</strong>.
                </li>
                <li>
                  Whilst spending 1 Custom Module, press Equipment Modification
                  to complete the process of overloading your gear.
                </li>
                <li>
                  Up to 3 random effects will appear. These initial effects are
                  always <strong>Tier 11</strong>, but further rerolls are not
                  entitled to this privilege.
                </li>
              </ol>
              <p>
                Don't like what you rolled? You can consult with our detailed OL
                rerolling guide. Do note that, while you may overload anyone you
                want, beware of several consequences:
              </p>
              <ul>
                <li>
                  An overloaded gear cannot be removed from the Nikke by any
                  means and for any reason. So, you can't just slap it on
                  Modernia for campaign, and then transfer it to Scarlet for
                  PvP.
                </li>
                <li>
                  You lose the T9M gear. While beforehand you can still move the
                  gear around depending on what unit is playing, now that's no
                  longer possible.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="What To Get With Overload" />
              <StaticImage
                src="../../../images/nikke/generic/ol_5.webp"
                alt="Guide"
              />
              <p>
                This is where stuff gets complicated. If our reason to overload
                is solely for CP or stats, it is pretty straightforward. Upgrade
                them, get higher tier lines, and you are done. However, most
                Nikke(s) will perform significantly better if you obtain the
                correct built-in buffs (lines) for them. For example, a Charge
                Speed line would be useless for Scarlet, but a Max Ammo line can
                improve her damage by beyond 40%! Surely just yoloing with a set
                of random enhancements isn't exactly profitable, right?
              </p>
              <p>
                To learn more about the interaction between Overload Lines and
                CP, check the <strong>How Overload Impacts CP</strong> section
                below.
              </p>
              <p>
                In this guide, we will walk you step-by-step through all the
                buffs provided by Overload and present the strengths &
                weaknesses of each. If you are looking for our carefully
                compiled list of OL recommendations (with order of priority!)
                that you can use as reference for building your units to
                perfection, skip to:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="OL Gear - Recommendations"
                  link="/nikke/guides/overload-gear-recommendations"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_olrec.jpg"
                      alt="OL Gear - Recommendations"
                    />
                  }
                />
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Overload Targets & Priorities" />
              <div className="nikke-ol-targets">
                <div className="single-group box">
                  <h5>Attack</h5>
                  <div className="inside">
                    <p>
                      The most basic stat that never goes wrong, ATK will
                      undoubtedly benefit any Nikke, although it may be
                      transparent and less preferred on some. In general, we
                      will almost always recommend ATK as one of your targets
                      when building DPS, but its rank in the scale of priorities
                      may vary across units. Here is our thought process:
                    </p>
                    <ul>
                      <li>
                        If a unit's kit already possesses a high amount of ATK
                        buff that has high uptime (like Scarlet's), a phenomenon
                        called Dilution will dampen the efficacy of our ATK
                        lines.
                      </li>
                      <li>
                        For these units, Elemental DMG should be prioritized
                        over ATK as they have a higher cost-return efficiency,
                        even if they only work against a specific element. The
                        same concept also applies in the other direction, but on
                        average, Elemental DMG is rarely diluted.
                      </li>
                      <li>
                        We like to consider both ATK & Elemental DMG as direct
                        damage amplifications, but they do not enhance a unit's
                        functional capabilities. Some units desire functional
                        upgrades more than direct damage boosts.
                      </li>
                      <li>
                        Hence, unless there are other lines to prioritize
                        specifically for the Nikke in question (Charge Speed on
                        Alice; Max Ammo for MGs; others), ATK will be placed on
                        top of the hierarchy alongside Elemental DMG.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="single-group box">
                  <h5>Elemental Damage</h5>
                  <div className="inside">
                    <p>
                      Another direct damage amplifier that multiplies the damage
                      of your Nikke against the countered element by a certain
                      percentage. It is generally superior to ATK because it
                      establishes a higher cap and is less prone to dilution,
                      but it is also less versatile. Here is our thought
                      process:
                    </p>
                    <ul>
                      <li>
                        Elemental DMG is always good, 99% of the time, because
                        nowadays ATK buffs are easily diluted.
                      </li>
                      <li>
                        Elemental DMG allows your characters to "carry" you
                        against their preferred bosses in Solo Raid and even
                        against certain Nikke(s) in PvP.
                      </li>
                      <li>
                        The damage increase is very noticeable, especially at
                        higher levels. I mean, who can't see a 20%+ uptick in
                        damage output?
                      </li>
                      <li>
                        Higher if not the same priority as ATK when there is no
                        drastic dilution. The only argument for prioritizing ATK
                        is if the unit is present in a lot of content (like
                        Alice) and is your only DPS, or if the element is seldom
                        profitable (like in PvP because it is dominated by
                        certain elements), but this advice is not rigid.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="single-group box">
                  <h5>Max Ammunition</h5>
                  <div className="inside">
                    <p>
                      An indirect damage amplification and functional upgrade
                      that boosts the uptime of a unit, which may or may not be
                      significant. Less reloading = less downtime = less doing
                      nothing. If they shoot more, they do more damage, right?
                      Well, yeah, but the effect is less pronounced on some
                      units.
                    </p>
                    <ul>
                      <li>
                        Perfect for units with long downtime(s), such as
                        Scarlet, Modernia, Alice, etc. On the right units, they
                        are way superior to ATK & Elemental DMG.
                      </li>
                      <li>
                        Less effective on units who are not reliant on Normal
                        Attacks for damage, such as Snow White or Maxwell, whose
                        damage is contained within their Burst Skill. Still,
                        some Max Ammo will still help with overall damage and
                        Burst Generation (e.g. spamming Maxwell's Normal Attack
                        to charge Burst).
                      </li>
                      <li>
                        Thus, more shots mean less reload, which also mean
                        higher Burst Generation potential. Particularly true
                        with SR/RL with Manual Quick Scopes.
                      </li>
                      <li>
                        Its value diminishes the more Max Ammo a unit has, but
                        on certain units and weapon types, this point of
                        diminishing return is never reached (as with most MGs).
                      </li>
                      <li>
                        Its efficiency is also modulated by Reload Speed buffs
                        in the team. Max Ammo improves DPS by increasing the
                        uptime and in turn the uptime:downtime ratio, but Reload
                        Speed does the same by minimizing the downtime. As a
                        result, in tandem, they may reach the point of
                        diminishing return much faster.
                      </li>
                      <li>
                        Great for units who rely on "after X shots" mechanics to
                        trigger their skills and may be necessary for some.
                      </li>
                      <li>
                        {' '}
                        Conversely, some units have mechanics antagonistic
                        toward Max Ammo, such as "last bullet", in which case
                        you must avoid Max Ammo at all costs and wash them away
                        should they show up. This is especially true if they
                        tamper with a unit too much (more apparent on SAnis, but
                        not so much on Privaty because she is a support).
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="single-group box">
                  <h5 className="medium">Hit Rate</h5>
                  <div className="inside">
                    <p>
                      An enhancement tailored for weapons with poor accuracy,
                      such as SMGs, SGs, and ARs to some extent. The goal of Hit
                      Rate is pretty simple: helping you land more shots/core
                      shots on the enemy and minimize "misses". Core shots
                      provide a massive damage multiplier, so Hit Rate must be
                      broken, right? No. The point of observable return is
                      unreasonably high, and you need to stack a lot of Hit Rate
                      before it manifests. This is because Hit Rate is a
                      separate multiplier from accuracy. AR doesn't magically
                      transform into a laser with 50% Hit Rate. Furthermore,
                      about half of the weapon types, including AR, work just
                      fine without additional Hit Rate.
                    </p>
                    <ul>
                      <li>
                        Reinforces units with poor accuracy and makes them more
                        viable against smaller or farther enemies.
                      </li>
                      <li>
                        Easier core hits mean greater profit from core hit
                        buffs.
                      </li>
                      <li>
                        More pellets/shots landed mean more consistent
                        activation of specific skills.
                      </li>
                      <li>
                        Some units may have a special interaction with Hit Rate
                        (i.e. Modernia), which contribute to their
                        compatibility. One is enough to permanently trigger S2,
                        but it is not mandatory.
                      </li>
                      <li>
                        Supplemental and corrective in nature but almost never a
                        core aspect. Tends to be very low in priority unless
                        there is a particular need or the composition qualifies
                        for Hit Rate stacking.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="single-group box">
                  <h5 className="medium">Charge Speed</h5>
                  <div className="inside">
                    <p>
                      Proportionally cuts down the time needed to charge an
                      SR/RL to a specific percentage by X% seconds, relative to
                      the default Charge Time. In other words, it can be thought
                      of as increasing Fire Rate. A unit with 1.5s Charge Time
                      and 40% Charge Speed will take 0.9s to fully charge. Its
                      effect is more prominent the closer it is to 100%, at
                      which point the resulting Charge Time is nearly zero.
                    </p>
                    <ul>
                      <li>
                        Only applicable to certain units; useless for others.
                      </li>
                      <li>
                        The perceived Fire Rate increase slightly boosts the
                        unit's DPS, which intensifies as the stat approaches
                        100%. Ideal with skillsets that already have high Charge
                        Speed themselves.
                      </li>
                      <li>
                        The increased Fire Rate also helps quicken RL timings in
                        PvP, giving you an an edge.
                      </li>
                      <li>
                        Charge Speed may also be used in PvP to regulate the
                        chances of RL whiffing and target skipping in your or
                        enemy's favor (double-edged sword).
                      </li>
                      <li>
                        Charge Speed also interacts with most special abilities
                        (i.e. Snow White's Burst Skill), potentially allowing
                        them to benefit from time-limited buffs such as Liter's.
                      </li>
                      <li>
                        Some units may have a special interaction with Charge
                        Speed (i.e. Red Hood), which contribute to their
                        compatibility.
                      </li>
                      <li>
                        Some units have breakpoints that you can chase to enjoy
                        an elevation in damage.
                      </li>
                      <li>
                        Not as important as ATK & Elemental DMG, unless it is
                        direly required for a unit to function (i.e. Alice).
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="single-group box">
                  <h5 className="low">Charge Damage</h5>
                  <div className="inside">
                    <p>
                      A direct damage amplification that only works on SR/RL by
                      additively increasing their default Charge Damage by the
                      value shown, without increasing the Charge Time. A unit
                      with 250% Charge Damage getting a 5% Charge Damage OL will
                      have a maximum Charge Damage of 255%. Hence, we can view
                      this as increasing the attack modifier of charged weapons.
                    </p>
                    <ul>
                      <li>
                        Only applicable to certain units; useless for others.
                      </li>
                      <li>
                        As it internally interacts with modifiers, it doesn't
                        get diluted by common buffs, but it still dilutes
                        itself. It is less effective on units with already high
                        enough base Charge Damage, let alone special abilities
                        with over 1000% (e.g. Snow White's Burst Skill).
                      </li>
                      <li>
                        Usually never a priority because the damage gain is tiny
                        but otherwise an acceptable bonus line. Often
                        overshadowed by Charge Speed, which is more universal
                        and helps a lot with Arena.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="single-group box">
                  <h5 className="low">Critical Rate</h5>
                  <div className="inside">
                    <p>
                      A controversial buff because CRIT is underplayed in the
                      damage formula, as it is often diluted by other major
                      damage modifiers like Core Hit, Full Burst Bonus, and
                      Damage Distance. However, when these other modifiers are
                      absent, the untapped potential of CRIT awaits discovery.
                      This is especially true with ShiftUp's recent tendency to
                      conceal cores for over half the fight.
                    </p>
                    <ul>
                      <li>
                        Dependent on Critical Damage stat to bring impact. Not
                        many compositions feature CRIT to a healthy level.
                      </li>
                      <li>
                        Even then, it is minimal unless the other major
                        modifiers are absent, which is almost always the case
                        with shotguns (because their poor accuracy doesn't allow
                        for many core hits) or special attacks that are usually
                        aimed somewhere off core (such as Snow White's Burst
                        Skill being used to overkill parts).
                      </li>
                      <li>
                        Very low in priority because the current meta is
                        predominantly occupied by accurate weapons. However, if
                        SGs and SMGs are to make their comeback, CRIT will be
                        favorable to them.
                      </li>
                      <li>
                        We usually aim for Critical Rate OL when a unit's kit is
                        saturated with Critical Damage and vice versa.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="single-group box">
                  <h5 className="low">Critical Damage</h5>
                  <div className="inside">
                    <p>
                      Again, another sad buff that doesn't deserve our heartless
                      treatment.
                    </p>
                    <ul>
                      <li>Dependent on Critical Rate stat to bring impact.</li>
                      <li>Similar characteristics and consequences.</li>
                      <li>
                        Choose this instead of its sibling if a unit already has
                        too much Critical Rate.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="single-group box">
                  <h5 className="low">Defense</h5>
                  <div className="inside">
                    <p>
                      Worthless because DEF is underplayed in the damage
                      formula. Can be used to redirect attacks that target units
                      of the highest/lowest DEF both in PvE and PvP, but there
                      is no real usage that would warrant or justify keeping
                      this.
                    </p>
                    <ul>
                      <li>
                        A pitfall trap whose only purpose is to annoy you and
                        reduce your chance of obtaining good rolls.
                      </li>
                      <li>
                        I guess random appearances of DEF can boost your CP?
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="How Overload Impacts CP" />
              <p>
                Toward the end of May 2024, ShiftUp decided to make a surprising
                leap forward, a decision that would balance the game in a better
                direction. That is allowing Overload Lines to affect CP.
                Previously, Overload Gear only boosts CP via base stat increase.
                Now, it provides extra CP in addition based on its rolls:
              </p>
              <ul>
                <li>
                  <strong>The Number of Lines with Effects</strong> - The more
                  number of effects a gear has, the more the CP gain. Two rolls
                  are usually better than a singular roll.
                </li>
                <li>
                  <strong>The Collective Tiers of the Effects</strong> -
                  However, more is not always better. The sum of [tiers]
                  (#what-to-get-with-overload) of all effects combined is what
                  matters. Hence, if you have one singular Tier 11 Hit Rate
                  (11.81%), it is better than having Tier 3 DEF (6.18%) + Tier 4
                  CRIT Rate (3.32%).
                </li>
                <li>
                  <strong>The Number of Elemental Damage Lines</strong> -
                  Generally speaking, all effects such as ATK, Crit Rate, Max
                  Ammo, etc. give the same amount of CP per Tier. However,
                  Elemental Damage is the only effect which gives more CP per
                  Tier. Hence, the more elemental damage lines on your unit, the
                  higher their CP! In other words, Tier 11 Elemental Damage will
                  give more CP than a Tier 11 ATK.
                </li>
              </ul>
              <p>
                If you want to calculate what CP your Nikke would have if they
                had specific Overload Lines, favorite items, cubes or gear, you
                can utilize this CP Calculator, made by one of the most trusted
                theorycrafters in our Discord community server (
                <strong>Lorax2233</strong>). To access:
              </p>
              <Button
                variant="primary"
                href="https://docs.google.com/spreadsheets/d/1bcn6cSZOqt_9UcV8162oIF5SezrPybZwRktTe0nh1S8/edit?gid=722944657#gid=722944657"
                target="_blank"
                rel="noreferrer"
              >
                Nikke Combat Power Calculator
              </Button>
              <br />
              <h5>More guides</h5>
              <p>Check our other guides in the Overload Gear Series!</p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="OL Gear - Recommendations"
                  link="/nikke/guides/overload-gear-recommendations"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_olrec.jpg"
                      alt="OL Gear - Recommendations"
                    />
                  }
                />
                <CategoryCard
                  title="OL Gear - Rerolling"
                  link="/nikke/guides/overload-gear-reroll"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_olreroll.jpg"
                      alt="OL Gear - Rerolling"
                    />
                  }
                />
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Unlocking Overload</a>
                </li>
                <li>
                  <a href="#section-2">Overloading Your Gear</a>
                </li>
                <li>
                  <a href="#section-3">What To Get With Overload</a>
                </li>
                <li>
                  <a href="#section-4">Overload Targets & Priorities</a>
                </li>
                <li>
                  <a href="#section-5">How Overload Impacts CP</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesOLIntro;

export const Head: React.FC = () => (
  <Seo
    title="Overload Gear (OL) - Intro | NIKKE | Prydwen Institute"
    description="Guide for the Overload Gear (OL) in NIKKE - the introduction part."
  />
);
